import { BaseFieldDef } from "@r8er/components/field/definition";
import { FormProps } from "@r8er/components/form";
import { clsx, type ClassValue } from "clsx";
import { camelCase, snakeCase } from "lodash";
import { useRouter } from "next/router";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { twMerge } from "tailwind-merge";
import { Writeable } from "zod";
import { api } from "./api";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export type Unpromisify<T> = T extends Promise<infer R> ? R : never;

export const useOnClickOutside = (
  ref: MutableRefObject<HTMLElement> | undefined,
  handler: Function
) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const useHover = () => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
};

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const usePrevious = <T = unknown>(value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useOnScreen = (
  ref: MutableRefObject<Element>,
  rootMargin = "0px"
) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export const useDebounce = <T>(value: T, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
};

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle) && void 0;
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export const useDarkMode = () => {
  // Use our useLocalStorage hook to persist state through a page refresh.
  // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
  const [enabledState, setEnabledState] =
    typeof window !== "undefined"
      ? useLocalStorage("dark-mode-enabled")
      : useState(false);

  // See if user has set a browser or OS preference for dark mode.
  // The usePrefersDarkMode hook composes a useMedia hook (see code below).
  const prefersDarkMode = usePrefersDarkMode();

  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const enabled =
    typeof enabledState !== "undefined" ? enabledState : prefersDarkMode;

  // Fire off effect that add/removes dark mode class
  useEffect(
    () => {
      const className = "darkMode";
      const element = window.document.body;
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    },
    [enabled] // Only re-call effect when value changes
  );

  // Return enabled state and setter
  return [enabled, setEnabledState];
};

// Compose our useMedia hook to detect dark mode preference.
// The API for useMedia looks a bit weird, but that's because ...
// ... it was designed to support multiple media queries and return values.
// Thanks to hook composition we can hide away that extra complexity!
// Read the recipe for useMedia to learn more: usehooks.com/useMedia
export const usePrefersDarkMode = () =>
  useMedia(["(prefers-color-scheme: dark)"], [true], false);

export const useLocalStorage = <T = unknown>(key: string, initialValue?: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const useMedia = <T = unknown>(
  queries: string[],
  values: T[],
  defaultValue: T
) => {
  // Array containing a media query list for each query
  const mediaQueryLists =
    typeof window !== "undefined"
      ? queries.map((q) => window.matchMedia(q))
      : [];

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () =>
        mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};

export const useWhyDidYouUpdate = <P extends {} = {}>(
  name: string,
  props: P
) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<P>();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.info("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

// export const useEventListener = (eventName, handler, element = window) => {
//     // Create a ref that stores handler
//     const savedHandler = useRef();

//     // Update ref.current value if handler changes.
//     // This allows our effect below to always get latest handler ...
//     // ... without us needing to pass it in effect deps array ...
//     // ... and potentially cause effect to re-run every render.
//     useEffect(() => {
//         savedHandler.current = handler;
//     }, [handler]);

//     useEffect(
//         () => {
//             // Make sure element supports addEventListener
//             // On
//             const isSupported = element && element.addEventListener;
//             if (!isSupported) return;

//             // Create event listener that calls handler function stored in ref
//             const eventListener = (event) => savedHandler.current(event);

//             // Add event listener
//             element.addEventListener(eventName, eventListener);

//             // Remove event listener on cleanup
//             return () => {
//                 element.removeEventListener(eventName, eventListener);
//             };
//         },
//         [eventName, element] // Re-run if eventName or element changes
// );
// }

export interface Address {
  line_1: string;
  line_2: string;
  line_3: string;
  postal_code: string;
  region: string;
  city: string;
  country: string;
}

export const removeEmpty = (obj: Object) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      newObj[key] = removeEmpty(obj[key] as Object);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const classes = cn;
export const mergeClasses = (...themes: Record<string, string>[]) => {
  return themes.reduce<Record<string, string>>((acc, theme) => {
    const innerTheme = theme
      ? Object.entries(theme).reduce<Record<string, string>>(
          (innerAcc, [key, className]) => {
            return {
              ...innerAcc,
              [key]: innerAcc[key]
                ? classes(className, innerAcc[key])
                : className,
            };
          },
          acc
        )
      : {};

    return {
      ...acc,
      ...innerTheme,
    };
  }, {});
};

export const cameliseKeys = (obj: Record<string, unknown>) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => cameliseKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: cameliseKeys(obj[key] as Record<string, unknown>),
      }),
      {}
    );
  }
  return obj;
};

export const snakeKeys = (obj: Record<string, unknown>) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: snakeKeys(obj[key] as Record<string, unknown>),
      }),
      {}
    );
  }
  return obj;
};

export const fromJSONBuffer = (content?: Buffer) =>
  content
    ? typeof content === "string"
      ? content
      : Buffer.from(Object.values(content)).toString()
    : undefined;

export const usePath = (path: string) => {
  const [url, setUrl] = useState(path);
  useEffect(() =>
    setUrl(
      typeof window !== "undefined"
        ? `${window.location.pathname}/${path}`
        : path
    )
  );

  return url;
};

export const notNullEmpty = (value: unknown) =>
  undefined !== value &&
  null !== value &&
  "" !== value &&
  (!Array.isArray(value) || value.length !== 0);

export const validate =
  (...rules: BaseFieldDef["validate"][]) =>
  (record, field, { showError }) => {
    let validated = true;
    if (!notNullEmpty(field.value)) {
      validated = false;
      if (showError) showError(`Field is required`);
    }
    rules.forEach((rule) => {
      const ruleValidated =
        typeof rule == "function" ? rule(record, field, { showError }) : rule;
      if (validated) validated = ruleValidated;
    });
    return validated;
  };

export const validateRequired = (record, field, { showError }) => {
  const validated = notNullEmpty(field.value);
  if (!validated && showError) showError(`Field is required`);
  return validated;
};

export const validateNotZero = (record, field, { showError }) => {
  const validated = notNullEmpty(field.value) && field.value > 0;
  if (!validated && showError)
    showError(`${field.definition.label ?? "Score"} cannot be 0`);
  return validated;
};

export const validateYearRequired = (record, field, { showError }) => {
  let validated = validateRequired(record, field, { showError });
  if (validated) return validateYear(record, field, { showError });
  return validated;
};

export const validateYear = (record, field, { showError }) => {
  const validated =
    null === field.value ||
    undefined === field.value ||
    "" === field.value ||
    field.value?.toString()?.length === 4;
  if (!validated && showError) showError("Year must be four digits");
  return validated;
};

const isValidDate = (d: Date) => !Number.isNaN(d.getMilliseconds());

export const validateDate = (record, field, { showError }) => {
  const validated =
    null === field.value ||
    undefined === field.value ||
    "" === field.value ||
    isValidDate(new Date(field.value));
  if (!validated && showError) showError("Date is not valid");
  return validated;
};

export const useMessage = () => {
  const [sent, setSent] = useState(false);

  const sendMessage: (
    userId: string,
    { setIsLoading }: { setIsLoading: (val: boolean) => void }
  ) => FormProps["onSubmit"] =
    (userId, { setIsLoading }) =>
    async (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      await setIsLoading(true);
      const formData = new FormData(form);

      const message = formData.get("message").toString();
      await api.mutation("user.sendMessage", {
        message,
        userId,
      });

      setSent(true);

      const messageField = form.elements.namedItem(
        "message"
      ) as HTMLTextAreaElement;

      await setIsLoading(false);

      messageField.value = "";
    };

  return [sent, sendMessage] as [
    boolean,
    (
      userId: string,
      config: { setIsLoading: (val: boolean) => void }
    ) => FormProps["onSubmit"]
  ];
};

export const useWarningOnExit = (shouldWarn) => {
  const Router = useRouter();
  const message =
    "You have unsaved changes\nAre you sure you want to leave this page?";

  const lastHistoryState = useRef(global.history?.state);
  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistoryState.current = history.state;
    };
    Router.events.on("routeChangeComplete", storeLastHistoryState);
    return () => {
      Router.events.off("routeChangeComplete", storeLastHistoryState);
    };
  }, []);

  useEffect(() => {
    let isWarned = false;

    const routeChangeStart = (url) => {
      if (Router.asPath !== url && shouldWarn && !isWarned) {
        isWarned = true;
        if (window.confirm(message)) {
          Router.push(url);
        } else {
          isWarned = false;
          Router.events.emit("routeChangeError");

          // HACK
          const state = lastHistoryState.current;
          if (
            state != null &&
            history.state != null &&
            state.idx !== history.state.idx
          ) {
            history.go(state.idx < history.state.idx ? -1 : 1);
          }

          // eslint-disable-next-line no-throw-literal
          throw "Abort route change. Please ignore this error.";
        }
      }
    };

    const beforeUnload = (e) => {
      if (shouldWarn && !isWarned) {
        const event = e || window.event;
        event.returnValue = message;
        return message;
      }
      return null;
    };

    Router.events.on("routeChangeStart", routeChangeStart);
    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [message, shouldWarn]);
};

export const useStateCallback = <T>(
  initialState: T
): [T, (state: T, cb?: (state: T) => void) => void] => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: T) => void) | undefined>(undefined); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state: T, cb?: (state: T) => void) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `undefined` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};
export const writeable = <T>(data: T) => data as Writeable<T>
