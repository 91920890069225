import { classes } from "@r8er/lib/utils";
import Link from "next/link";
import { Column, Columns } from "../columns";
import {
  IconSocialInstagram,
  IconSocialLinkedIn,
  IconSocialTwitter,
} from "../icons";
import styles from "./footer.module.css";

const Header = () => {
  return (
    <footer className={styles.marketing__footer}>
      <div className={styles.marketing__footer_inner}>
        <div className={styles.marketing__footer_primary}>
          <Columns columnStructure="75% auto">
            <Column>
              <div>
                <Link href="/about">About Us</Link>
                <Link href="/team">Our Team</Link>
                <Link href="/articles">News and Updates</Link>
                <Link href="/faqs">FAQs</Link>
                <Link href="/contact">Contact Us</Link>
              </div>
              <div className={styles.subFooter}>
                <Link href="/terms">Terms of Service</Link>
                <Link href="/policy">Privacy Policy</Link>
                <Link href="/policy">Acceptable Use Policy</Link>
                <Link href="/cookies">Cookie Policy</Link>
              </div>
            </Column>
            <Column classList={[styles.marketing__footer_right]}>
              <IconSocialTwitter className={styles.marketing__footer_icon} />
              <IconSocialInstagram className={styles.marketing__footer_icon} />
              <IconSocialLinkedIn className={styles.marketing__footer_icon} />
              <span className={styles.marketing__footer_text}>Follow Us</span>
            </Column>
          </Columns>
        </div>
      </div>
      <div className={styles.marketing__footer_secondary}>
        <div className={styles.marketing__footer_inner}>
          Copyright &copy; 2020-{new Date().getFullYear()} R8ER LTD. All rights
          reserved.
        </div>
        <div
          className={classes(
            styles.marketing__footer_inner,
            styles.marketing__footer_inner_sub
          )}
        >
          R8ER LTD is registered in England and Wales no. 12770875 with
          registered office at 85 Great Portland Street, First Floor, London,
          W1W 7LT
        </div>
      </div>
    </footer>
  );
};

export default Header;
